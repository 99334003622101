export let FONT_LIST = [

    //Metrically compatible to Arial
    //https://fonts.google.com/specimen/Arimo
    {
        name: "Arimo",
        url: "res/fonts/arimo-v11-latin-regular.woff",
        subs: ["arial", "arialmt", "helvetica", "sans serif", "sans-serif"]
    },

    //Metrically compatible to Times
    //https://fonts.google.com/specimen/Tinos
    {
        name: "Tinos",
        url: "res/fonts/tinos-v11-latin-regular.woff",
        subs: ["times", "serif"]
    },

    //Metrically compatible to Courier
    //https://fonts.google.com/specimen/Cousine
    {
        name: "Cousine",
        url: "res/fonts/cousine-v12-latin-regular.woff",
        subs: ["courier", "monospace"]
    }
];