/**
 * PDF Text Layer is hiden on top of our drawings
 * And it will provide the possible user experience to select/hightlight/copy/search
 * From Vector PDF Document
 */

import "./textlayer.css";
import * as PDFJS from "@adsk/pdfjs-dist";
import { cssFontExtractor } from "./PDFFontExtractor";

var av = Autodesk.Viewing;
var avu = Autodesk.Viewing.UI;

export function PDFTextLayer(viewer, usingTextLayer) {
  this.viewer = viewer;
  this.setGlobalManager(viewer.globalManager);

  this.onModelLoaded = this.onModelLoaded.bind(this);
  this.onRenderFinished = this.onRenderFinished.bind(this);
  this.onModelUnload = this.onModelUnload.bind(this);

  viewer.addEventListener(av.GEOMETRY_LOADED_EVENT, this.onModelLoaded);
  viewer.addEventListener(av.PROGRESS_UPDATE_EVENT, this.onRenderFinished);
  viewer.addEventListener(av.MODEL_UNLOADED_EVENT, this.onModelUnload);

  this.usingTextLayer = usingTextLayer;
  this.textSelectionEnabled = false;
};

av.GlobalManagerMixin.call(PDFTextLayer.prototype);

PDFTextLayer.prototype.addToolbarButton = function () {var _this = this;
  if (this.toolButtonAdded) return;
  this.toolButtonAdded = true;

  if (!this.viewer.getToolbar) return;
  var toolbar = this.viewer.getToolbar();
  if (!toolbar) return;
  var modelTools = toolbar.getControl(Autodesk.Viewing.TOOLBAR.MODELTOOLSID);
  if (!modelTools) return;

  var button = new avu.Button('toolbar-pdf-text-selection');
  // Need CSS for the ICON and the actual icon
  button.setIcon("adsk-icon-pdf-textselection");
  button.setToolTip("Select Text");
  modelTools.addControl(button);
  this.toolbarButton = button;


  button.onClick = function () {
    _this.textSelectionEnabled = !_this.textSelectionEnabled;
    if (_this.textLayerDiv) {
      if (_this.textSelectionEnabled) {
        _this.textLayerDiv.classList.add("enabled");
        _this.viewer.toolController.enableMouseButtons(false);
      } else {
        _this.textLayerDiv.classList.remove("enabled");
        _this.viewer.toolController.enableMouseButtons(true);
        _this.clearSelection();
      }
      _this.toolbarButton.setState(_this.textSelectionEnabled ? avu.Button.State.ACTIVE : avu.Button.State.INACTIVE);
    }
  };
};

PDFTextLayer.prototype.clearSelection = function () {
  var _document = this.getDocument();
  var _window = this.getWindow();
  if (_window.getSelection) {
    if (_window.getSelection().empty) {// Chrome
      window.getSelection().empty();
    } else if (_window.getSelection().removeAllRanges) {// Firefox
      _window.getSelection().removeAllRanges();
    }
  } else if (_document.selection) {// IE?
    _document.selection.empty();
  }
};

PDFTextLayer.prototype.destroy = function () {
  this.onModelUnload();

  this.viewer.removeEventListener(av.GEOMETRY_LOADED_EVENT, this.onModelLoaded);
  this.viewer.removeEventListener(av.PROGRESS_UPDATE_EVENT, this.onRenderFinished);
  this.viewer.removeEventListener(av.MODEL_UNLOADED_EVENT, this.onModelUnload);

  this.removeToolbarButton();
};

PDFTextLayer.prototype.removeToolbarButton = function () {
  if (this.toolbarButton) {
    this.toolbarButton.removeFromParent();
    this.toolbarButton = null;
  }
};

PDFTextLayer.prototype.onRenderFinished = function (event) {
  if (event.percent == 100 && event.state === av.ProgressState.RENDERING && this.centerTextlayer) {
    this.centerTextlayer();
  }
};

PDFTextLayer.prototype.onModelUnload = function () {
  this.centerTextlayer = null;
  if (this.textLayerDiv) {
    this.textLayerDiv.remove();
    this.textLayerDiv = null;
  }
};

PDFTextLayer.prototype.onModelLoaded = function (event) {var _this2 = this;
  if (this.viewer.model && this.viewer.model.isPdf() && typeof this.viewer.model.getData().getPDF === "function") {
    var pageNumber = this.viewer.model.getData().metadata.currentPage;
    var pdf = this.viewer.model.getData().getPDF();
    pdf.getPage(pageNumber).then(function (page) {
      _this2.renderTextLayer(page);
    });
  }
};

PDFTextLayer.prototype.renderTextLayer = function renderTextlayer(page) {var _this3 = this;
  var _document = this.getDocument();
  var readableStream = page.streamTextContent({ normalizeWhitespace: true, combineTextItems: true });
  var textLayerDiv = _document.createElement('div');
  var options = {
    scale: 1,
    rotation: page.rotate,
    dontFlip: false };

  var viewport = page.getViewport(options);
  textLayerDiv.style.height = "".concat(viewport.height, "px");
  textLayerDiv.style.width = "".concat(viewport.width, "px");
  textLayerDiv.classList.add("pdf-textlayer");

  var scope = this;

  this.viewer.canvasWrap.append(textLayerDiv);
  this.textLayerDiv = textLayerDiv;

  // loop the style to check whether this text was loaded
  function getLoadedFonts() {
    var _document = scope.getDocument();
    var nodes = _document.getElementsByTagName("style");
    var names = {};
    for (var i = 0; i < nodes.length; i++) {
      if (nodes[i].getAttribute("datatype") === "fonts") {
        names[nodes[i].getAttribute("id")] = 1;
      }
    }
    return names;
  }

  // need to make the html overlay the same position as canvas
  function mapModelBoundsToScreen() {
    var bounds = scope.viewer.model.getBoundingBox();
    var min = scope.viewer.worldToClient(bounds.min);
    var max = scope.viewer.worldToClient(bounds.max);

    return {
      min: {
        x: Math.min(min.x, max.x),
        y: Math.min(min.y, max.y),
        z: Math.min(min.z, max.z) },

      max: {
        x: Math.max(min.x, max.x),
        y: Math.max(min.y, max.y),
        z: Math.max(min.z, max.z) } };


  }

  function centerTextlayer() {
    // get transform matrix for the textLayer
    // scale
    var screenBounds = mapModelBoundsToScreen();
    var width = screenBounds.max.x - screenBounds.min.x;
    var height = screenBounds.max.y - screenBounds.min.y;
    var scale1 = Math.min(width / viewport.width, height / viewport.height);

    var matrix = new THREE.Matrix4().makeTranslation(viewport.width / 2, viewport.height / 2, 0);
    matrix = new THREE.Matrix4().makeScale(scale1, scale1, 1).multiply(matrix);
    matrix = new THREE.Matrix4().makeTranslation(-viewport.width / 2, -viewport.height / 2, 0).multiply(matrix);
    var left = screenBounds.min.x;
    var top = screenBounds.min.y;

    matrix = new THREE.Matrix4().makeTranslation(left, top, 0).multiply(matrix);
    var el = matrix.elements;
    var transform1 = "matrix(".concat([el[0], el[1], el[4], el[5], el[12], el[13]].join(","), ")");
    textLayerDiv.style.transform = transform1;
  }

  function render() {
    var textDivs = [];
    return PDFJS.renderTextLayer({
      pageIndex: page.pageNumber,
      textDivs: textDivs,
      viewport: viewport,
      enhanceTextSelection: true,
      textContentStream: readableStream,
      container: textLayerDiv }).
    promise.then(function () {
      if (scope.usingTextLayer) {
        textLayerDiv.classList.add("visible");
      }
      if (textDivs.length > 0) {
        scope.addToolbarButton();
      }
      centerTextlayer();
    });
  }

  var promise = Promise.resolve();
  if (this.usingTextLayer) {
    // for now only extract the font library when we show the text layer
    // because it will increase the loading time, will not use it for selection
    // But, if we decide to use it for selection, the selection/highlight will be more accurate
    promise = cssFontExtractor(page, getLoadedFonts()).then(function (data) {
      var fonts = [];
      var _document = _this3.getDocument();
      for (var i = 0; i < data.length; i++) {
        var fontStyle = _document.createElement("style");
        _document.getElementsByTagName("head")[0].append(fontStyle);
        fontStyle.innerHTML = data[i].css;
        fontStyle.setAttribute("id", data[i].name);
        fontStyle.setAttribute("datatype", "fonts");
        fonts.push(data[i].name);
      }

      return fonts;
    }).then(function (names) {
      // preload the fonts
      var _document = _this3.getDocument();
      var promises = names.map(function (name) {
        return _document.fonts.load("10pt ".concat(name));
      });
      return Promise.all(promises);
    }).catch();
  }

  promise.then(render);

  this.centerTextlayer = centerTextlayer;
};