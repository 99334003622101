import * as opentype from "opentype.js";

/**
                                          * In PDF, text encoding was handled differently, all the char code was transcoded
                                          * When we want to show the text in the browser, we have to transcode it back
                                          * Then user can see the correct font type and also can do selection/copy
                                          * If we don't transcode it back, we have to do the unicode for each text, 
                                          * and select/copy will not be possible
                                          * 
                                          * @param {Number} encoded 
                                          * @param {PDF.FontFaceObject} fontInfo 
                                          */
function locateCharacter(encoded, fontFace) {
  // Locate the index of the charecter
  var index = -1;
  for (var i = 0; i < fontFace.toFontChar.length; i++) {
    if (fontFace.toFontChar[i] === encoded) {
      index = i;
      break;
    }
  }

  if (index != -1 && fontFace.toUnicode._map) {
    var character = fontFace.toUnicode._map[index];
    var width = fontFace.widths[index];
    return {
      character: character,
      width: width };

  } else {
    // it might be white space or font part
  }
}

export function cssFontExtractor(page, loadedFonts) {
  loadedFonts = loadedFonts || {};
  var commonObjs = page.commonObjs.objs;
  var fonts = [];
  for (var key in commonObjs) {
    var obj = commonObjs[key].data;
    // check it is a font
    if (obj.loadedName && obj.fallbackName && obj.data) {
      if (loadedFonts[obj.loadedName]) {
        continue;
      }

      var font = opentype.parse(obj.data.buffer);

      // need to locate the charector and change the glyph
      // PDF use swapped charactor code mapping, in order to make browser work
      // we have to change the glyph back to the original ascii/unicode value
      var glyphs = font.glyphs.glyphs;
      for (var glyphKey in glyphs) {
        var glyph = glyphs[glyphKey];
        if (!glyph.name && !glyph.unicode) {
          // undefined name will trigger exception in the encoding stage
          glyph.name = "";
        } else {
          var charInfo = locateCharacter(glyph.unicode, obj);
          if (!charInfo || charInfo.character == undefined) {
            // undefined name will trigger exception in the encoding stage
            glyph.name = "";
          } else {
            glyph.name = charInfo.character;
            glyph.unicode = charInfo.character.charCodeAt(0);
            glyph.unicodes = [glyph.unicode];
          }
        }
      }
      fonts.push({
        fontName: key,
        data: font });

      font.names.fontFamily.en = key;
      font.names.fontSubfamily.en = "Regular";
    }
  }

  var promises = fonts.map(function (font) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      // font.data.toArrayBuffer is slow
      // Forked the opentype.js and make the encoding part much faster
      // Consider to do a PR to opentype.js in future
      var dataView = new DataView(font.data.toArrayBuffer());
      var blob = new Blob([dataView], { type: 'font/opentype' });
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        resolve({
          css: "\n                    @font-face {\n                        font-family: ".concat(

          font.fontName, ";\n                        src: url('").concat(
          reader.result, "');\n                    }"),

          name: font.fontName });

      };
    });
  });

  return Promise.all(promises).then(function (data) {
    return data;
  });
}