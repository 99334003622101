function _typeof(obj) {"@babel/helpers - typeof";if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {_typeof = function _typeof(obj) {return typeof obj;};} else {_typeof = function _typeof(obj) {return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;};}return _typeof(obj);}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}
import * as PDFJS from "@adsk/pdfjs-dist";

import { theFontEngine } from "./font-engine";


// Delay-init variables that require Autodesk.Extension.CompGeom
var LmvCanvasContext = null;
var isRef = null;
var refKey = null;

var initCompGeom = function initCompGeom() {
  LmvCanvasContext = Autodesk.Extensions.CompGeom.LmvCanvasContext;
  isRef = LmvCanvasContext.isRef;
  refKey = LmvCanvasContext.refKey;
};

//import { generateTestPattern } from "./test-pattern";
var TEST_PATTERN = false;

// LMV-5060 When user change model, we don't know the intent why?
// User might navigate to another page in the same PDF or trying to load a new different model
// In order to save loading time, we need to use this object try to defer the destroy method of previous PDF worker
// Then we can try to avoid memory leak in the meantime to make it much faster to navigate between pages 
var PDFWorkingReferenceMap = {};
var DefferedDestroyTime = 3000; // 3 seconds

var av = Autodesk.Viewing,
avp = av.Private;

// FOR IE11 ONLY, LMV-5380
if (typeof document !== "undefined" && document.currentScript === undefined) {
  PDFJS.GlobalWorkerOptions.workerSrc = avp.getResourceUrl("extensions/PDF/PDF.worker.js");
}

/** @constructor */
export function PDFLoader(parent) {

  initCompGeom();

  this.isPdfLoader = true; // For QA only
  this.viewer3DImpl = parent;
  this.setGlobalManager(this.viewer3DImpl.globalManager);
  this.loading = false;
  this.tmpMatrix = new THREE.Matrix4();

  this.logger = avp.logger;
  this.loadTime = 0;
  this.notifiesFirstPixel = true;
  var _window = this.getWindow();

  this.dtor = this.dtor.bind(this);
  this.viewer3DImpl.api.addEventListener(av.VIEWER_UNINITIALIZED, this.dtor, { once: true });
}

av.GlobalManagerMixin.call(PDFLoader.prototype);

/**
                                                  * BubbleNode Structure for PDF data will help to do page navigation
                                                  * @param {PDF} pdf PDF object from pdf.js
                                                  */
PDFLoader.prototype.createPDFDocument = function (pdf) {
  var numPages = pdf.numPages;
  var guid = pdf.fingerprint;
  var me = this;
  // Let the generator stay along with document lifecycle, it will give us a chance to maximum the caching
  // to get the best performance
  var parent = {
    guid: guid,
    type: "design",
    "hasThumbnail": "false",
    "progress": "complete",
    "status": "success",
    "success": "100%",
    "name": "PDF",
    "isVectorPDF": true,
    "role": "viewable",
    totalRasterPixels: 0,
    getPDF: function getPDF() {// this can avoid issue in searilization 
      if (pdf && pdf._transport && !pdf._transport.destroyed) {
        return pdf;
      } else {
        return null;
      }
    } };


  var children = [];

  for (var i = 1; i <= numPages; i++) {
    children.push({
      guid: guid + "/" + i,
      type: "geometry",
      role: "2d",
      status: "success",
      progress: "complete",
      viewableID: guid + "/" + i,
      name: av.i18n.translate("Page %s", { postProcess: 'sprintf', sprintf: [i] }),
      page: i,
      children: [{
        role: av.BubbleNode.PDF_PAGE_NODE.role,
        page: i,
        type: "geometry",
        status: "success",
        progress: "commplete",
        urn: me.svfUrn }] });


  }

  parent.children = children;
  return new av.Document(parent);
};


PDFLoader.prototype.dtor = function () {var _this = this;
  if (this.svf && this.svf.propDbLoader) {
    this.svf.propDbLoader.dtor();
    this.svf.propDbLoader = null;
  }

  this.currentLoadPath = null;
  this.isf2d = undefined;
  this.viewer3DImpl.api.removeEventListener(av.VIEWER_UNINITIALIZED, this.dtor);

  if (this.pdf) {
    // Reset the flag synchronized here
    delete PDFWorkingReferenceMap[this.pdf.fingerprint];
    setTimeout(function () {
      // If the PDF is used in DefferedDestroyTime time, the PDF will be still alive
      // Otherwise the pdf worker is gonna be destroyed, and the document has been load again.
      if (!PDFWorkingReferenceMap[_this.pdf.fingerprint]) {
        _this.pdf.destroy();
        _this.pdf = null;
      }
    }, DefferedDestroyTime);
  }

  if (this._renderTask) {
    this._renderTask.cancel();
    this._renderTask = null;
  }

  this.svf = null;
  this.options = null;
  avp.logger.log("PDFLoader destroy");
};


PDFLoader.prototype.loadFile = function (path, options, onSuccess, onWorkerStart) {
  if (this.loading) {
    avp.logger.log("Loading of PDF already in progress. Ignoring new request.");
    return false;
  }
  this.loading = true;

  var urnIdx = path.indexOf('urn:');
  if (urnIdx !== -1) {
    // Extract urn:adsk.viewing:foo.bar.whateverjunks out of the path URL and bind it to logger.
    // From now on, we can send logs to viewing service, and logs are grouped by urn to make Splunk work.
    path = decodeURIComponent(path);
    var urn = path.substr(urnIdx, path.substr(urnIdx).indexOf('/'));
    avp.logger.log("Extracted URN: " + urn);

    // Extract urn(just base64 code)
    var _index = urn.lastIndexOf(':');
    this.svfUrn = urn.substr(_index + 1);

    //V2 only accepts URL encoded paths
    var qIdx = path.indexOf("?", urnIdx);
    if (qIdx !== -1) {
      path = path.slice(0, urnIdx) + encodeURIComponent(path.slice(urnIdx, qIdx)) + path.slice(qIdx);
    } else
    {
      path = path.slice(0, urnIdx) + encodeURIComponent(path.slice(urnIdx));
    }
  } else {
    this.svfUrn = path;
  }

  this.sharedDbPath = options.sharedPropertyDbPath;
  this.currentLoadPath = path;
  this.acmSessionId = options.acmSessionId;

  //This is done to avoid CORS errors on content served from proxy or browser cache
  //The cache will respond with a previously received response, but the Access-Control-Allow-Origin
  //response header might not match the current Origin header (e.g. localhost vs. developer.api.autodesk.com)
  //which will cause a CORS error on the second request for the same resource.
  this.queryParams = [
  this.acmSessionId && "acmsession=".concat(this.acmSessionId),
  av.endpoint.getQueryParams()].
  filter(function (p) {return p;}).join('&');

  this.options = options;

  if (this.options.placementTransform) {
    //NOTE: The scale of the placement transform is not always sufficient to
    //determine the correct scale for line widths. This is because when a 2D model (in inches) is
    //loaded into a 3d scene in feet, the transform includes all the scaling needed to get into feet
    //but the model space line weight for the drawing is relative to the drawing itself, so an extra
    //factor of 12 would be needed in such case to cancel out the 1/12 needed for inch->foot.
    //This could probably be automatically derived, but in an error prone way, so I'm leaving it
    //up to the application layer that does the model aggregation to pass in the right model scale as an option.
    this.modelScale = this.options.modelScale || this.options.placementTransform.getMaxScaleOnAxis();
  } else {
    this.modelScale = this.options.modelScale || 1;
  }

  var scope = this;

  scope.loadFydoCB(path, options, onSuccess, onWorkerStart);

  return true;
};

PDFLoader.prototype.getDocument = function () {
  return this.options.bubbleNode.getRootNode().data;
};

PDFLoader.prototype.getFontGenerator = function () {var _this2 = this;
  return this.viewer3DImpl.api.loadExtension("Autodesk.MSDF").then(function () {
    var generator = new Autodesk.MSDF.Generator();
    generator.setGlobalManager(_this2.globalManager);
    return generator;
  });
};

PDFLoader.prototype.createFontAtlas = function (page, cacheKey) {var _this3 = this;
  if (PDFLoader.enableMSDFText) {
    // Fetching the operation list will trigger the font loading
    // The loaded font event will be trigger in next tick
    // So we need use timeout to let the app continue
    return new Promise(function (resolve, reject) {
      page.getOperatorList("display").then(function () {
        setTimeout(function () {
          _this3.getFontGenerator().then(function (generator) {
            generator.createFontAtlasForPDF(page, cacheKey).then(function (fontAtlas) {
              resolve(fontAtlas);
            });
          });
        }, 0);
      });
    });
  } else {
    return Promise.resolve();
  }
};

PDFLoader.prototype.evaluatePageLineStyles = function (page) {var _this4 = this;
  return page.getOperatorList("display").then(function (data) {
    var w = 0;
    var cacheSet = new Set();

    for (var i = 0; i < data.fnArray.length; i++) {
      if (data.fnArray[i] == PDFJS.OPS.setDash) {
        w = Math.max(w, data.argsArray[i][0].length);
        var key = data.argsArray[i].join("/");
        cacheSet.add(key);
      }
    }

    var h = cacheSet.size + 1;
    _this4.pageLineStyleParams = {
      width: w,
      height: h };

  });
};

/**
    * LMV-5175
    * PDF with inlineImage/inlineImageGroup/inlineXImage caused huge performance issue on loading time
    * and huge memory usage as well. The visual effect is also wrong. 
    * This function is here to address the issue by loop all the operations from the page,
    * combine the inlineImage all together to a large image, and send it to LMVCanvasContext for drawing
    * LMVCanvasContext will only draw 1 time per group, and the render is happend on the first draw call
    * From any of its children inline image
    * 
    * Important: This function ideally should only process those scan-line/inlined images with each draw call 1 pixel width|height
    * 
    * TODO:
    * 1. Build a stack to track the transformation matrix (So far the way I did looks a little bit hack,
    * but it works well. So if it has issue in future we can always revisit this issue). Building a stack
    * is a little bit overhead at this moment. 
    */
PDFLoader.prototype.mergeInilineImages = function (page) {var _this5 = this;
  // console.time("mergeInilineImages");
  return page.getOperatorList("display").then(function (data) {
    var imageMapInfo = {};
    var v1 = [0, 0];
    var v2 = [1, 1];
    var RECT_OVERLAP_TOLERANCE = 3;

    function tx(x, y, xform) {
      return x * xform[0] + y * xform[2] + xform[4];
    }
    function ty(x, y, xform) {
      return x * xform[1] + y * xform[3] + xform[5];
    }

    function getImageGeneralInfo(index, map) {
      var minScale = 1;

      var box2 = [Infinity, Infinity, -Infinity, -Infinity];
      var needRotate = false;
      var flipY = false;
      for (var i = 0; i < map.length; i++) {
        var entry = map[i];

        var transform = entry.transform;

        // Here we assume the whole image has the same rotation matrix
        if (transform[1] && transform[2]) {
          needRotate = true;
        }

        // if the image has rotation matrix other then 90 degree, do nothing
        if (transform[0] != 0 && transform[2] != 0 || transform[1] != 0 && transform[3] != 0) {
          return;
        }

        // We want to flip the image about the Y axis for JPEGs and Images.
        // In the PDFjs paintJpegXObject function the y is flipped, that is why we are doing it at this point as well. 
        flipY = data.fnArray[index] === PDFJS.OPS.paintJpegXObject;

        minScale = Math.min(Math.abs(transform[0] + transform[2]), Math.abs(transform[1] + transform[3]));

        // loop to get the size of the canvas
        var x1 = tx(v1[0], v1[1], transform);
        var x2 = tx(v2[0], v2[1], transform);
        var y1 = ty(v1[0], v1[1], transform);
        var y2 = ty(v2[0], v2[1], transform);
        box2[0] = Math.min(box2[0], x1, x2);
        box2[1] = Math.min(box2[1], y1, y2);
        box2[2] = Math.max(box2[2], x1, x2);
        box2[3] = Math.max(box2[3], y1, y2);
      }

      // want to reduce the impace to normal images, so we only deal with small image or paintInlineImageXObjectGroup, paintInlineImageXObject command
      if (box2[3] - box2[1] <= RECT_OVERLAP_TOLERANCE ||
      box2[2] - box2[0] <= RECT_OVERLAP_TOLERANCE ||
      data.fnArray[index] == PDFJS.OPS.paintInlineImageXObjectGroup) {
        imageMapInfo[index] = {
          bounds: new THREE.Box2(new THREE.Vector2(box2[0], box2[1]), new THREE.Vector2(box2[2], box2[3])),
          needRotate: needRotate,
          flipY: flipY,
          minScale: minScale };

      }
    }

    /**
       * This function returns an object containing the operation index of the transform and the setFillRGBColor.
       * The pattern for imageMasks is the following: setFillRGBColor, transform, paintImageMaskXObject.
       * The pattern can also be setFillRGBColor, save, transform, paintImageMaskXObject.
       */
    function getImageMaskInfo(imageMaskIndex) {
      var info = {};
      // get the transform index
      if (data.fnArray[imageMaskIndex - 1] == PDFJS.OPS.transform) {
        info.transform = imageMaskIndex - 1;
      }

      // Get the setFillRGBColor index.
      if (data.fnArray[imageMaskIndex - 2] == PDFJS.OPS.setFillRGBColor) {
        info.setFillRGBColor = imageMaskIndex - 2;
      } else if (data.fnArray[imageMaskIndex - 3] == PDFJS.OPS.setFillRGBColor) {
        info.setFillRGBColor = imageMaskIndex - 3;
      }

      return info;
    }

    function getImageInfo(imageIndex) {
      var info = {};
      // get the transform index
      if (data.fnArray[imageIndex - 1] == PDFJS.OPS.transform) {
        info.transform = imageIndex - 1;
      } else if (data.fnArray[imageIndex - 2] == PDFJS.OPS.transform) {
        info.transform = imageIndex - 2;
      }
      return info;
    }

    var imageDataCanvas = document.createElement("canvas");
    var imageDataCtx = imageDataCanvas.getContext("2d");

    function drawImageGroup(imageGroup) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");

      var bounds = imageGroup.bounds;
      var scale = 1 / imageGroup.minScale;
      var width = bounds.max.x - bounds.min.x;
      var height = bounds.max.y - bounds.min.y;

      width = width * scale;
      height = height * scale;

      var groups = Object.keys(imageGroup.itemMap);

      var originX = bounds.min.x;
      var originY = bounds.min.y;

      if (imageGroup.needRotate) {var _ref =
        [height, width];width = _ref[0];height = _ref[1];
      }
      canvas.width = width;
      canvas.height = height;

      function drawSubImageGroup(index) {
        if (data.fnArray[index] == PDFJS.OPS.transform) {
          return;
        }

        function constructMap(imageInfo, imageData) {
          return [
          {
            transform: data.argsArray[imageInfo.transform],
            x: 0,
            y: 0,
            w: imageData.width,
            h: imageData.height }];


        }

        var args = data.argsArray[index];
        var imageData, map, fillStyle;
        switch (data.fnArray[index]) {
          case PDFJS.OPS.paintInlineImageXObjectGroup:
            imageData = args[0];
            map = args[1];
            break;
          case PDFJS.OPS.paintImageMaskXObject:
            // LMV-5454
            // Get the transform and setFillRGBColor operation indices.
            var imageMaskInfo = getImageMaskInfo(index);
            imageData = args[0];
            // Set to RGB_24BPP if image kind is not set.
            // This is required for putBinaryImageData
            imageData.kind = imageData.hasOwnProperty('kind') ? imageData.kind : 2;
            map = constructMap(imageMaskInfo, imageData);
            // Format the fillStyle
            var rgb = data.argsArray[imageMaskInfo.setFillRGBColor];
            fillStyle = "rgb(".concat(rgb[0], ",").concat(rgb[1], ",").concat(rgb[2], ")");
            break;
          case PDFJS.OPS.paintJpegXObject:
          case PDFJS.OPS.paintImageXObject:
            // LMV-5667
            imageData = page.objs.get(args[0]);
            map = constructMap(getImageInfo(index), imageData);
            break;}


        imageDataCanvas.width = imageData.width;
        imageDataCanvas.height = imageData.height;
        imageDataCtx.clearRect(0, 0, imageData.width, imageData.height);
        // paintJpegXObject and paintImageXObject come in as images and not as imageData
        if (imageData instanceof Image) {
          imageDataCtx.drawImage(imageData, 0, 0);
        } else {
          PDFJS.putBinaryImageData(imageDataCtx, imageData);
        }

        // Set the fillStyle. This value is set for paintImageMaskXObject
        if (fillStyle) {
          imageDataCtx.fillStyle = fillStyle;
          imageDataCtx.fillRect(0, 0, imageData.width, imageData.height);
        }


        // loop to draw each line
        for (var i = 0; i < map.length; i++) {
          var entry = map[i];
          var transform = entry.transform;
          var x1 = tx(v1[0], v1[1], transform);
          var x2 = tx(v2[0], v2[1], transform);
          var y1 = ty(v1[0], v1[1], transform);
          var y2 = ty(v2[0], v2[1], transform);
          var x3 = Math.min(x1, x2);
          var x4 = Math.max(x1, x2);
          var y3 = Math.min(y1, y2);
          var y4 = Math.max(y1, y2);
          var x = (x3 - originX) * scale;
          var y = (y3 - originY) * scale;
          var dstWidth = (x4 - x3) * scale;
          var dstHeight = (y4 - y3) * scale;
          if (imageGroup.needRotate) {
            ctx.drawImage(imageDataCanvas, entry.x, entry.y, entry.w, entry.h, y, x, dstHeight, dstWidth);
          } else {
            ctx.drawImage(imageDataCanvas, entry.x, entry.y, entry.w, entry.h, x, y, dstWidth, dstHeight);
          }
        }
      }

      for (var i = 0; i < groups.length; i++) {
        drawSubImageGroup(groups[i]);
      }

      //
      if (canvas.width > 0 && canvas.height > 0 && imageGroup.needRotate == true) {
        var canvas1 = document.createElement("canvas");
        canvas1.width = canvas.height;
        canvas1.height = canvas.width;

        var ctx1 = canvas1.getContext("2d");
        ctx1.transform(0, 1, 1, 0, 0, 0);
        ctx1.drawImage(canvas, 0, 0);
        return canvas1;
      } else if (canvas.width > 0 && canvas.height > 0 && imageGroup.flipY == true) {
        var canvas1 = document.createElement('canvas');
        canvas1.width = canvas.width;
        canvas1.height = canvas.height;

        var ctx1 = canvas1.getContext('2d');
        ctx1.scale(1, -1);
        ctx1.drawImage(canvas, 0, -canvas.height, canvas.width, canvas.height);
        return canvas1;
      } else {
        return canvas;
      }
    }

    function hasIntersection(box1, box2) {
      return !(box1.max.x - box2.min.x <= -RECT_OVERLAP_TOLERANCE || // left
      box1.max.y - box2.min.y <= -RECT_OVERLAP_TOLERANCE || // bottom
      box1.min.x - box2.max.x >= RECT_OVERLAP_TOLERANCE || // right
      box1.min.y - box2.max.y >= RECT_OVERLAP_TOLERANCE); // top
    }

    function getEntryForInlineImageXObject(index) {
      return [{
        transform: data.argsArray[index - 1],
        x: 0,
        y: 0,
        w: data.argsArray[index][0].width,
        h: data.argsArray[index][0].height }];

    }

    function getEntryForDependenceImageXObject(index) {
      var transform;
      if (data.fnArray[index - 1] == PDFJS.OPS.transform) {
        transform = data.argsArray[index - 1];
      } else if (data.fnArray[index - 2] == PDFJS.OPS.transform) {
        transform = data.argsArray[index - 2];
      }
      return [{
        transform: transform,
        x: 0,
        y: 0,
        w: data.argsArray[index][1],
        h: data.argsArray[index][2] }];

    }

    function process() {
      for (var i = 0; i < data.fnArray.length; i++) {
        var _map = void 0;
        if (data.fnArray[i] == PDFJS.OPS.paintInlineImageXObjectGroup) {
          _map = data.argsArray[i][1];
        } else if (data.fnArray[i] == PDFJS.OPS.paintImageMaskXObject) {
          // LMV-5454 - Group imageMasks in a similarly as inline images.
          var imageMaskInfo = getImageMaskInfo(i);
          // Add the image mask only if the transform and the setFillRGBColor are available.
          if (imageMaskInfo.transform && imageMaskInfo.setFillRGBColor) {
            _map = [{ transform: data.argsArray[imageMaskInfo.transform] }];
          }
        } else if (
        data.fnArray[i] == PDFJS.OPS.paintJpegXObject ||
        data.fnArray[i] == PDFJS.OPS.paintImageXObject)
        {
          var imageGroupInfo = getImageInfo(i);
          if (imageGroupInfo.transform) {
            _map = [{ transform: data.argsArray[imageGroupInfo.transform] }];
          }
        }

        if (_map) {
          getImageGeneralInfo(i, _map);
        }
      }

      // need to figure out which part of the image can be merged
      var imageGroups = [];

      for (var index in imageMapInfo) {
        var merged = false;
        for (var _i = imageGroups.length - 1; _i >= 0; _i--) {
          if (hasIntersection(imageGroups[_i].bounds, imageMapInfo[index].bounds)) {
            merged = true;

            imageGroups[_i].bounds.union(imageMapInfo[index].bounds);
            imageGroups[_i].needRotate = imageGroups[_i].needRotate || imageMapInfo[index].needRotate;
            imageGroups[_i].itemMap[index] = _i;
            imageGroups[_i].minScale = Math.min(imageGroups[_i].minScale, imageMapInfo[index].minScale);
            if (data.fnArray[index] === PDFJS.OPS.paintImageMaskXObject) {
              // Make sure that the transforms aren't applied multiple times by pdf.js
              var _imageMaskInfo = getImageMaskInfo(index);
              imageGroups[_i].itemMap[_imageMaskInfo.transform] = _i;
            } else if (data.fnArray[index] === PDFJS.OPS.paintJpegXObject || data.fnArray[index] === PDFJS.OPS.paintImageXObject) {
              // LMV-5667
              var imageInfo = getImageInfo(index);
              imageGroups[_i].itemMap[imageInfo.transform] = _i;
            }
            break;
          }
        }

        if (!merged) {
          var map = _defineProperty({},
          index, imageGroups.length);


          if (data.fnArray[index] === PDFJS.OPS.paintImageMaskXObject) {
            var _imageMaskInfo2 = getImageMaskInfo(index);
            map[_imageMaskInfo2.transform] = imageGroups.length;
          }

          if (data.fnArray[index] === PDFJS.OPS.paintJpegXObject || data.fnArray[index] === PDFJS.OPS.paintImageXObject) {
            // LMV-5667
            var _imageInfo = getImageInfo(index);
            map[_imageInfo.transform] = imageGroups.length;
          }

          imageGroups.push({
            bounds: imageMapInfo[index].bounds,
            minScale: imageMapInfo[index].minScale,
            needRotate: imageMapInfo[index].needRotate,
            flipY: imageMapInfo[index].flipY,
            itemMap: map });

        }
      }

      var imageMapResult = {
        map: {},
        groups: [] };


      for (var _i2 = 0; _i2 < imageGroups.length; _i2++) {
        var group = imageGroups[_i2];

        var canvas = drawImageGroup(group);
        group.canvas = canvas;

        imageMapResult.groups.push(group);
        imageMapResult.map = Object.assign(imageMapResult.map, group.itemMap);
        group.itemMap = null;
      }

      return imageMapResult;
    }

    _this5.inlineImageGroups = process();
    // console.timeEnd("mergeInilineImages");
  }).catch(console.error);
};

/**
    * When we do synchronized loading pdf referenced object, it might return empty if that content was not loaded
    * Asynchronized way is better, but it increases the complexity of loading it
    * This function is prefly for parsing those references
    */
PDFLoader.prototype.loadMarkedContentPropertiesReferences = function (pdf, page) {
  var referenceObjMap = {};

  function getRefObj(ref) {
    return pdf.getObject(ref).then(function (data) {
      referenceObjMap[refKey(data.key)] = data.value;
      // loop in one more level for dictionary 
      var childPromises = [];
      if (data.isDictionary) {var _loop = function _loop(
        itemKey) {
          if (isRef(data.value[itemKey])) {
            if (referenceObjMap[refKey(data.value[itemKey])]) {
              data.value[itemKey] = referenceObjMap[refKey(data.value[itemKey])];
            } else {
              childPromises.push(pdf.getObject(data.value[itemKey]).then(function (data1) {
                data.value[itemKey] = data1.value;
                referenceObjMap[refKey(data1.key)] = data1.value;
              }).catch(avp.logger.log));
            }
          }};for (var itemKey in data.value) {_loop(itemKey);
        }
      }

      return Promise.all(childPromises);
    }).catch(avp.logger.log); // for any missing reference object, just ignore and continue
  }

  return page.getOperatorList().then(function (ops) {
    var promises = [];
    for (var i = 0; i < ops.fnArray.length; i++) {

      if (ops.fnArray[i] == PDFJS.OPS.beginMarkedContent || ops.fnArray[i] == PDFJS.OPS.beginMarkedContentProps) {
        var args1 = ops.argsArray[i];
        for (var j = 0; args1 && j < args1.length; j++) {
          if (isRef(args1[j])) {
            var promise = getRefObj(args1[j]);
            promises.push(promise);
          } else if (_typeof(args1[j]) == "object") {
            for (var key in args1[j]) {
              if (isRef(args1[j][key])) {
                var _promise = getRefObj(args1[j][key]);
                promises.push(_promise);
              }
            }
          }
        }
      }
    }

    return Promise.all(promises)
    // This is a nice to have feature, in case of anything wrong, ust ignore and continue
    .catch(avp.logger.log).
    then(function () {
      return referenceObjMap;
    });
  });
};

PDFLoader.prototype.loadFydoCB = function (path, options, onSuccess, onWorkerStart) {
  this.t0 = Date.now();

  var svfPath = avp.pathToURL(path);
  if (this.queryParams) {
    svfPath += '?' + this.queryParams;
  }

  var scope = this;

  if (onWorkerStart)
  onWorkerStart();

  PDFJS.disableFontFace = true;

  var params = {
    url: svfPath,
    withCredentials: true,
    disableFontFace: true,
    stopAtErrors: false,
    cMapUrl: options.cMapUrl };


  if (!avp.token.useCookie) {
    params.httpHeaders = av.endpoint.HTTP_REQUEST_HEADERS;
  }

  this.viewer3DImpl._signalNoMeshes();

  var fe = theFontEngine;

  //Start loading fonts.
  var loadFontsAsync = fe.loadFonts();


  if (TEST_PATTERN) {
    this.viewer3DImpl.api.loadExtension("Autodesk.CompGeom").then(function () {

      var toPage = 1 / 72;

      var viewport = {
        width: 612,
        height: 792 };


      scope.renderContext = new LmvCanvasContext(viewport, toPage, scope.processReceivedMesh2D.bind(scope), fe);

      scope.svf = {
        is2d: true,
        isPdf: true,
        viewports: [],
        layersMap: { "0": 0 },
        layerCount: 1,
        bbox: new THREE.Box3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(viewport.width * toPage, viewport.height * toPage, 0)),
        metadata: {
          page_dimensions: {
            page_width: 612 * toPage,
            page_height: 792 * toPage,
            page_units: "inch" } } };




      scope.onModelRootLoadDone(scope.svf);

      if (onSuccess)
      onSuccess(null, scope.model);

      scope.viewer3DImpl.api.fireEvent({ type: av.MODEL_ROOT_LOADED_EVENT, svf: scope.svf, model: scope.model });

      loadFontsAsync.then(function () {

        generateTestPattern(scope.renderContext);

        scope.renderContext.flushBuffer(0, true);

        scope.onGeomLoadDone();

        scope.loading = false;

      });

    });


    return true;
  }

  function getInternalLinks(pdf, page) {
    return page.getAnnotations().then(function (annotations) {
      if (annotations && annotations.length > 0) {
        return Promise.all(annotations.map(function (annotation) {
          // internal page link
          if (annotation.subtype == "Link" && !annotation.url) {
            var pageIndexPromise;

            // So far I found 2 ways of annotion destination, it might be more, extend this part if we have new case
            if (typeof annotation.dest == "string") {
              pageIndexPromise = pdf.getDestination(annotation.dest).then(function (destInfo) {
                return pdf.getPageIndex(destInfo[0]);
              });
            } else {
              if (annotation.dest instanceof Array) {
                pageIndexPromise = pdf.getPageIndex(annotation.dest[0]);
              }
            }
            if (pageIndexPromise) {
              return pageIndexPromise.then(function (pageIndex) {
                // need to register the rect to pageIndex
                // pdf getPage function start from 1, so here need add extra 1
                annotation.pageIndex = pageIndex + 1;
                return annotation;
              });
            }
          } else {
            // external link, will add support here, if needed
          }
        })).then(function (internalLinks) {
          return internalLinks.filter(function (item) {return item != undefined;});
        }).catch(function (error) {
          // If we have difficulty of parsing annotations, we should let the loading continue
          return [];
        });
      }
    });
  }

  function updateLinkBounds(renderContext, viewport, internalLinks) {
    var dbIdLinks = [];
    // Use negative dbId since hotArea is added by us
    var dbId = -2;
    var transform = renderContext.getCurrentTransform();
    for (var i = 0; internalLinks && i < internalLinks.length; i++) {
      var rect = createRectByViewportTransform(renderContext, transform, viewport, internalLinks[i].rect);
      var linkNode = scope.svf.loadOptions.bubbleNode.getRootNode().children[internalLinks[i].pageIndex - 1];
      dbIdLinks.push({
        dbId: dbId--,
        properties: [{
          displayValue: linkNode.data.viewableID }],

        box: [
        Math.min(rect[0], rect[2]),
        Math.min(rect[1], rect[3]),
        Math.max(rect[0], rect[2]),
        Math.max(rect[1], rect[3])] });


    }
    scope.dbIdLinks = dbIdLinks;
  }

  function createRectByViewportTransform(renderContext, transform, viewport, tempRect) {
    var rect = viewport.convertToViewportRectangle(tempRect);
    rect = [renderContext.tx(rect[0], rect[1], transform),
    renderContext.ty(rect[0], rect[1], transform),
    renderContext.tx(rect[2], rect[3], transform),
    renderContext.ty(rect[2], rect[3], transform)];
    return rect;
  }

  /**
     * Format strings output from PDF page
     * @param {Object} renderCtx - Render context of current page rendering.
     * @param {Object} pageInfo - dimensions of current page.
     * @param {Object} viewport - Viewport based on Page properties.
     * @param {Array} vectorStrings - Array with all strings from Vector PDF.
     * @returns {Object} - Object with list of strings and their stringBoxes.
     */
  function formatVectorPdfStrings(renderCtx, pageInfo, viewport, vectorStrings) {
    var strings = [];
    var stringBoxes = [];
    var stringAngles = [];
    var renderCtxTransform = renderCtx.viewport.transform;
    var currentTransform = renderCtx.getCurrentTransform();

    // Transform function that combines values from Render Context Transform and Transform of a string
    var transformFunc = function transformFunc(m1, m2) {
      return [
      m1[0] * m2[0] + m1[2] * m2[1],
      m1[1] * m2[0] + m1[3] * m2[1],
      m1[0] * m2[2] + m1[2] * m2[3],
      m1[1] * m2[2] + m1[3] * m2[3],
      m1[0] * m2[4] + m1[2] * m2[5] + m1[4],
      m1[1] * m2[4] + m1[3] * m2[5] + m1[5]];

    };

    //Strings in PDF.js are grouped, hence 2 loops needed to extract every expression.
    for (var i = 0; i < vectorStrings.length; i++) {
      if (vectorStrings[i].items) {
        var items = vectorStrings[i].items;

        for (var j = 0; j < items.length; j++) {
          var tempObject = items[j];
          var textWidth = tempObject.width;
          var textHeight = tempObject.height;
          var minX = 0;
          var minY = 0;
          var maxX = 0;
          var maxY = 0;

          // Get bounding box's angle of a string by using viewport and strings transform
          // Rotation of bbox applied on UI side based on angle
          var strTx = transformFunc(renderCtxTransform, tempObject.transform);
          var strAngle = Math.atan2(strTx[1], strTx[0]);

          // Creating Bounding box based on transform values from pdf.js object
          // 4-th place in array is vertical offset of string
          // 5-th place in array is horizontal offset of string
          switch (pageInfo.rotate) {
            case 0:
              minX = tempObject.transform[4] + textWidth;
              minY = tempObject.transform[5] + textHeight;
              maxX = tempObject.transform[4];
              maxY = tempObject.transform[5];
              break;
            case 90:
              minX = tempObject.transform[4] - textHeight;
              minY = tempObject.transform[5] + textWidth;
              maxX = tempObject.transform[4];
              maxY = tempObject.transform[5];
              break;
            case 180:
              minX = tempObject.transform[4] - textWidth;
              minY = tempObject.transform[5] - textHeight;
              maxX = tempObject.transform[4];
              maxY = tempObject.transform[5];
              break;
            case 270:
              minX = tempObject.transform[4] + textHeight;
              minY = tempObject.transform[5] - textWidth;
              maxX = tempObject.transform[4];
              maxY = tempObject.transform[5];
              break;
            default:
              break;}


          var min = new THREE.Vector2(minX, minY);
          var max = new THREE.Vector2(maxX, maxY);

          var tempRect = [min.x, min.y, max.x, max.y];

          var rect = createRectByViewportTransform(renderCtx, currentTransform, viewport, tempRect);

          strings.push(tempObject.str);
          stringBoxes.push(rect[0], rect[1], rect[2], rect[3]);
          stringAngles.push(strAngle);
        }
      }
    }
    return { strings: strings, stringBoxes: stringBoxes, stringAngles: stringAngles };
  }

  // Outputs the operator list
  function outputDebugInfo(operators) {
    function swap(json) {
      var ret = {};
      for (var key in json) {
        ret[json[key]] = key;
      }
      return ret;
    }

    var swapped = swap(PDFJS.OPS);
    var fnArray = operators.fnArray;
    var argsArray = operators.argsArray;
    var counter = {};
    var saveRestore = 0;

    for (var i = 0; fnArray && i < fnArray.length && i < 1000; i++) {

      if (swapped[fnArray[i]] === 'restore') {
        saveRestore--;
      }

      if (saveRestore) {
        console.log("".concat('----'.repeat(saveRestore), ">"), i, swapped[fnArray[i]], argsArray[i]);
      } else {
        console.log(i, swapped[fnArray[i]], argsArray[i]);
      }

      if (swapped[fnArray[i]] === 'save') {
        saveRestore++;
      }

      if (!counter[swapped[fnArray[i]]]) {
        counter[swapped[fnArray[i]]] = 1;
      } else {
        counter[swapped[fnArray[i]]]++;
      }
    }

    console.log(counter);
  }

  function buildLayers(page) {
    return page.getOperatorList("display").then(function (ops) {
      // Uncomment to output the operators
      // outputDebugInfo(ops);

      var fnArray = ops.fnArray;
      var argsArray = ops.argsArray;
      var layers = {};
      var layerStartIndex = 1;

      for (var i = 0; fnArray && i < fnArray.length; i++) {
        var fn = fnArray[i];
        if (fn === PDFJS.OPS.beginMarkedContentProps) {
          if (argsArray[i] && argsArray[i][0] == "OC") {
            var key = void 0;
            if (_typeof(argsArray[i][1]) == "object" && argsArray[i][1].ocgId != null) {
              key = argsArray[i][1].ocgId;
            } else {
              key = argsArray[i][1];
            }
            if (layers[key] === undefined) {
              layers[key] = layerStartIndex++;
            }
          }
        }
      }
      return layers;
    })
    // It should not have error, but we don't test enough for PDF
    // In case of potential error happened, we still want to show the contents
    .catch(avp.logger.log).
    then(function (layers) {
      return scope.pdf.getPageOCGNames(page.ref).
      catch(avp.logger.log).
      then(function (layerNameMap) {
        layerNameMap = layerNameMap || {};
        var layersRoot = { name: 'root', id: 'root', children: [], isLayer: false };
        var layersMap = { "0": 0 };
        var layerCount = 1;
        for (var layerId in layers) {
          var l = layerNameMap[layerId];
          if (!l) {
            // when we did not find corresponding layer data, we should override the layerId to 0
            // and draw the content to layer 0. LMV-5242
            layers[layerId] = 0;
          } else {
            var name = _typeof(l) === 'object' && l.hasOwnProperty('name') ? l.name : layerId;
            var layer = {
              name: name,
              id: 'group-' + layers[layerId],
              isLayer: true,
              index: layers[layerId],
              visible: l.visible,
              children: [] };

            // for layers, the first drawing layer should be at the bottom of the list
            layersRoot.children.unshift(layer);
            layerCount++;
            layersMap[layers[layerId]] = layers[layerId];
          }
        }

        return {
          layerCount: layerCount,
          layersRoot: layersRoot,
          layersMap: layersMap,
          layers: layers };

      });
    });
  }

  function getStrings(page) {
    var documentStrings = [];
    var readableStream = page.streamTextContent({ normalizeWhitespace: true, combineTextItems: true });
    return new Promise(function (resolve, reject) {
      var pump = function pump() {
        reader.read().then(function (_ref2) {var value = _ref2.value,done = _ref2.done;
          if (done) {
            resolve(documentStrings);
            return;
          }
          documentStrings.push(value);
          pump();
        }, reject);
      };
      var reader = readableStream.getReader();
      pump();
    });
  }

  function renderPage(pageNumber) {var _this6 = this;
    var pdf = scope.pdf;
    PDFWorkingReferenceMap[pdf.fingerprint] = 1;

    if (pageNumber <= 0 || pageNumber > pdf.numPages) {
      pageNumber = 1;
    }

    if (scope.model) {
      scope.viewer3DImpl.unloadModel(scope.model);
      scope.model = undefined;
    }
    var context = {};
    var documentStrings = [];
    return pdf.getPage(pageNumber).then(function (page) {
      return scope.createFontAtlas(page, pdf.fingerprint).then(function (fontAtlas) {
        context.fontAtlas = fontAtlas;
        return getInternalLinks(pdf, page).then(function (internalLinks) {
          context.internalLinks = internalLinks;
        });
      }).then(function () {
        // Get PDS strings from page
        return getStrings(page);
      }).then(function (strings) {
        documentStrings = strings;
        return scope.detectCircle(page);
      }).then(function () {
        return scope.loadMarkedContentPropertiesReferences(pdf, page).then(function (map) {
          context.pdfRefObjMap = map;
        });
      }).then(function () {
        return scope.evaluatePageLineStyles(page);
      }).then(function () {
        return scope.mergeInilineImages(page);
      }).then(function () {var _scope$options$bubble, _scope$options$placem, _scope$options$placem2;
        var internalLinks = context.internalLinks;
        var view = page.view;
        view = view.slice(0);

        // LMV-5149: Apply the page rotation to the page_width and page_height
        var rotationMatrix = new THREE.Matrix4().makeRotationZ(page.rotate / 180 * Math.PI);
        var rotatedViewVec1 = new THREE.Vector3(view[0], view[1], 0).applyMatrix4(rotationMatrix);
        var rotatedViewVec2 = new THREE.Vector3(view[2], view[3], 0).applyMatrix4(rotationMatrix);


        // Apply the rotation vector to the view
        // These values are used to calculate the page_width and the page_height
        view[0] = Math.min(rotatedViewVec1.x, rotatedViewVec2.x);
        view[1] = Math.min(rotatedViewVec1.y, rotatedViewVec2.y);
        view[2] = Math.max(rotatedViewVec1.x, rotatedViewVec2.x);
        view[3] = Math.max(rotatedViewVec1.y, rotatedViewVec2.y);

        // need to put a cap on 1 dimention : 4096 for desktop / 2048 for mobile
        // LMV-4731: If we set the viewport too large, it will fail to load in Firefox
        var maxDim = Math.max(view[2] - view[0], view[3] - view[1]);
        var capDim = av.isMobileDevice() ? 2048 : 4096;
        var scale = capDim / maxDim;

        var DPI = 300;
        //Render the PDF at 300 pixels/inch instead of 72 points/inch.
        //This seems to match what Adobe Reader does at max zoom.
        var scaleByDPI = DPI / (72 / (page.userUnit || 1));

        scale = Math.min(scaleByDPI, scale);

        // Important: to keep the measure tool to get the same measurement as before
        var toInches = 1.0 / 72 / scale;

        // Convert to source file unit's if supplied in the bubble.
        var targetUnits = ((_scope$options$bubble = scope.options.bubbleNode) === null || _scope$options$bubble === void 0 ? void 0 : _scope$options$bubble.getSourceFileUnits()) || av.ModelUnits.INCH;

        // Make sure we get the correct unit string. For example "inch" -> "in".
        targetUnits = Autodesk.Viewing.Model.prototype.getUnitData(targetUnits).unitString;

        var toTargetUnits = Autodesk.Viewing.Private.convertUnits(av.ModelUnits.INCH, targetUnits, 1, toInches);

        //Negative rotation because we skip the y-flip.
        //TODO: need to check if this is right for all rotations, if not just let it flip y and negate that in the canvas context.
        var options = {
          scale: scale,
          rotation: -page.rotate,
          dontFlip: true };

        var viewport = page.getViewport(options);
        viewport.clipToViewport = true;

        scope.renderContext = new LmvCanvasContext(viewport, toTargetUnits, scope.processReceivedMesh2D.bind(scope), fe, PDFLoader.useTextLayer, context.fontAtlas, context.pdfRefObjMap);

        // update lineStyles
        scope.renderContext.setLineStyleParam(scope.pageLineStyleParams);
        scope.viewer3DImpl.matman().setLineStyleTexture(scope.renderContext.lineStyleTexture);
        scope.renderContext.setInlineImageGroups(scope.inlineImageGroups);

        scope.renderContext.setCircleInfo(scope.circleInfo);

        // Assign consecutive dbIds. The only purpose of these IDs is to split the page down into smaller shapes, so that 
        // we can use the ID buffer to quickly find shapes for snapping.
        scope.renderContext.consecutiveIds = true;

        scope.svf = {
          is2d: true,
          isPdf: true,
          viewports: [],
          layersMap: { "0": 0 },
          layerCount: 1,
          bbox: new THREE.Box3(new THREE.Vector3(viewport.offsetX * toTargetUnits, viewport.offsetY * toTargetUnits, 0),
          new THREE.Vector3((viewport.offsetX + viewport.width) * toTargetUnits, (viewport.offsetY + viewport.height) * toTargetUnits, 0)),
          metadata: {
            page_dimensions: {
              page_width: (view[2] - view[0]) * (page.userUnit || 1) / 72,
              page_height: (view[3] - view[1]) * (page.userUnit || 1) / 72,
              logical_width: viewport.width,
              logical_height: viewport.height,
              logical_offset_x: viewport.offsetX,
              logical_offset_y: viewport.offsetY,
              page_units: targetUnits },

            currentPage: pageNumber },

          placementTransform: (_scope$options$placem = scope.options.placementTransform) === null || _scope$options$placem === void 0 ? void 0 : _scope$options$placem.clone(),
          placementWithOffset: (_scope$options$placem2 = scope.options.placementTransform) === null || _scope$options$placem2 === void 0 ? void 0 : _scope$options$placem2.clone(),
          strings: [],
          stringDbIds: [],
          getPDF: function getPDF() {
            return scope.pdf;
          } };


        var t0 = performance.now();

        // layers information is required before we call onSuccess
        buildLayers(page).then(function (layersInfo) {
          scope.svf.layersRoot = layersInfo.layersRoot;
          scope.svf.layerCount = layersInfo.layerCount;
          scope.svf.layersMap = layersInfo.layersMap;
          scope.renderContext.layers = layersInfo.layers;
        })
        // Ignore any potential error and let the rendering continue
        .catch(avp.logger.log).
        then(function () {
          if (scope.currentLoadPath === null) {
            var errorMsg = "PDF loader was destroyed";
            onSuccess && onSuccess(errorMsg);
            return Promise.reject(errorMsg);
          };

          scope.onModelRootLoadDone(scope.svf);
          if (onSuccess) {
            onSuccess(null, scope.model);
          }
          scope.viewer3DImpl.api.fireEvent({ type: av.MODEL_ROOT_LOADED_EVENT, svf: scope.svf, model: scope.model });
        }).then(function () {
          //
          // Render PDF page into canvas context
          //
          loadFontsAsync.then(function () {
            var renderTask = scope._renderTask = page.render(scope.renderContext, _this6);
            renderTask.promise.then(function () {var _documentStrings, _model$getDocumentNod, _model$getDocumentNod2;
              if (internalLinks && internalLinks.length > 0) {
                // render hot area in the canvas to enable internal links
                // Need to render the hotArea after main page was rendered, 
                // because extra dbID will be assigned to hotArea
                if (PDFLoader.enableHyperlinks) {
                  updateLinkBounds(scope.renderContext, viewport, internalLinks);
                  scope.svf.metadata.hyperLinks = scope.dbIdLinks;
                  scope.viewer3DImpl.api.loadExtension("Autodesk.Hyperlink");
                }
              }

              // Format PDF document strings
              if (((_documentStrings = documentStrings) === null || _documentStrings === void 0 ? void 0 : _documentStrings.length) > 0) {
                var formattedStrings = formatVectorPdfStrings(scope.renderContext, page._pageInfo, viewport, documentStrings);
                scope.svf.strings = formattedStrings.strings;
                scope.svf.stringBoxes = formattedStrings.stringBoxes;
                scope.svf.stringAngles = formattedStrings.stringAngles;
              }

              scope.renderContext.finish();
              scope.svf.viewports = scope.renderContext.viewports;
              scope.svf.minLineWidth = scope.renderContext.currentVbb.minLineWidth;
              scope.svf.maxObjectNumber = scope.renderContext.maxDbId;
              scope.onGeomLoadDone();

              var t1 = performance.now();
              var pdfLoadTime = t1 - t0;
              console.log("PDF load time", pdfLoadTime);

              var model = scope.model;
              var geomList = model.getGeometryList();

              var dataToTrack = {
                load_time: scope.loadTime,
                pdf_load_time: pdfLoadTime,
                polygons: geomList.geomPolyCount,
                fragments: model.getFragmentList().getCount(),
                mem_usage: geomList.gpuMeshMemory,
                total_raster_pixels: (_model$getDocumentNod = model.getDocumentNode()) === null || _model$getDocumentNod === void 0 ? void 0 : (_model$getDocumentNod2 = _model$getDocumentNod.data) === null || _model$getDocumentNod2 === void 0 ? void 0 : _model$getDocumentNod2.totalRasterPixels };

              avp.analytics.track('viewer.model.loaded', dataToTrack);

              //These are needed in order to free the PDF loader context (it caches all PDF opcodes
              //in a giant array). Ideally we will modify the pdf.js library to not accumulate opcodes
              //that are already processed, so we don't spike memory at load time.
              renderTask._canvas = null;
            }).catch(function (err) {
              // Usually happens when the switching to another PDF page while 
              // the current one is still getting rendered.
              avp.logger.log(err);
            }).finally(function () {
              scope._renderTask = null;
              page.cleanup();
              scope.cleanup();
            });
          });
        });
      });
    }).catch(function (error) {
      return Promise.reject(error);
    });
  }

  Promise.resolve().then(
  function () {
    if (options.bubbleNode && typeof options.bubbleNode.getRootNode().data.getPDF == "function" && options.bubbleNode.getRootNode().data.getPDF()) {
      scope.pdf = options.bubbleNode.getRootNode().data.getPDF();
      scope.loading = false;
      renderPage(options.bubbleNode.data.page);
    } else {
      var pdfTask = PDFJS.getDocument(params);
      pdfTask.onProgress = function (_ref3) {var loaded = _ref3.loaded,total = _ref3.total;
        var percentLoaded = Math.round(loaded * 100 / total);
        scope.viewer3DImpl.signalProgress(percentLoaded, av.ProgressState.LOADING);
      };
      // keep pdfTask reference for cleanup later
      scope.pdfTask = pdfTask;

      return pdfTask.promise.then(function (pdf) {
        scope.pdf = pdf;
        scope.loading = false;
        var pageNumber = options.page || parseInt(avp.getParameterByName("page")) || 1;

        if (scope.options && !scope.options.bubbleNode) {
          var pdfMain = scope.createPDFDocument(pdf);
          scope.options.bubbleNode = pdfMain.getRoot().children[pageNumber - 1];
        }

        return renderPage(pageNumber);
      }).catch(function (error) {
        onSuccess && onSuccess(error);
      });
    }

  });


  return true;
};

/**
    * Loop all the operator command to detect whether it is a circle.
    */
PDFLoader.prototype.detectCircle = function (page) {var _this7 = this;
  var threshold = 0.001;
  var magic = 4 / 3 * Math.tan(Math.PI / 8);
  var magic2 = magic * magic;
  var p1 = new THREE.Vector2();
  var p2 = new THREE.Vector2();
  var p3 = new THREE.Vector2();
  var p4 = new THREE.Vector2();

  function equal(a, b) {
    return Math.abs(a - b) <= threshold;
  }

  function relativelyEqual(a, b) {
    return a > 0 && b > 0 && equal(Math.abs(a - b) / a, threshold);
  }

  /**
     *  if it is a primitive circle 
     *  it will have a 13, 15, 15, 15, 15, [18]
     *  Reference: https://stackoverflow.com/questions/1734745/how-to-create-circle-with-b%C3%A9zier-curves
     * @param {*} fnArray 
     * @param {*} argsArray 
     */
  function checkPattern(fnArray, argsArray) {
    if ((fnArray.length == 5 || fnArray.length == 6) && argsArray.length == 26) {
      if (fnArray[0] == 13 && fnArray[1] == 15 && fnArray[2] == 15 && fnArray[3] == 15 && fnArray[4] == 15 && (fnArray[5] == 18 || fnArray[5] == undefined)) {
        // do the math to figure out whether it is a circle
        for (var i = 0; i < argsArray.length - 6; i += 6) {
          p1.set(argsArray[i], argsArray[i + 1]);
          p2.set(argsArray[i + 2], argsArray[i + 3]);
          p3.set(argsArray[i + 4], argsArray[i + 5]);
          p4.set(argsArray[i + 6], argsArray[i + 7]);
          if (!checkQuaterCircle(p1, p2, p3, p4)) {
            return false;
          }
        }

        return true;
      }
    }
    return false;
  }

  function checkQuaterCircle(p1, p2, p3, p4) {
    var p12 = p2.clone().sub(p1);
    var p43 = p3.clone().sub(p4);
    var l12 = p12.lengthSq();
    var l43 = p43.lengthSq();
    var radius = p4.distanceToSquared(p1) / 2;

    var result = l12 > 0 && l43 > 0 && relativelyEqual(l12, l43) &&
    equal(p12.normalize().dot(p43.normalize()), 0) &&
    equal(l12 / radius, magic2) &&
    equal(l43 / radius, magic2);

    return result;
  }

  return page.getOperatorList("display").then(function (ops) {
    var circleInfo = {};
    for (var i = 0; i < ops.fnArray.length; i++) {
      if (ops.fnArray[i] == PDFJS.OPS.constructPath) {
        // need try to detect whether there is circle in this command
        var fn1 = ops.argsArray[i][0];
        var args1 = ops.argsArray[i][1];

        if (checkPattern(fn1, args1)) {
          p1.set(args1[0], args1[1]);
          p2.set(args1[6], args1[7]);
          p3.set(args1[12], args1[13]);
          p4.set(args1[18], args1[19]);

          var p12 = p2.clone().sub(p1);
          var p23 = p3.clone().sub(p2);

          var l12 = p12.lengthSq();
          var l23 = p23.lengthSq();
          var l34 = p3.distanceToSquared(p4);
          var l41 = p4.distanceToSquared(p1);

          // check the 4 points is a square
          if (relativelyEqual(l12, l23) && relativelyEqual(l34, l41) && relativelyEqual(l12, l41) && equal(p12.normalize().dot(p23.normalize()), 0)) {
            var x = 0,y = 0;
            for (var k = 0; k < args1.length - 6; k += 6) {
              x += args1[k];
              y += args1[k + 1];
            }

            circleInfo[i] = [x / 4, y / 4];
          }
        }
      }
    }

    _this7.circleInfo = circleInfo;
  });
};


PDFLoader.prototype.processReceivedMesh = function (mdata) {

  //Find all fragments that instance this mesh
  var meshid = mdata.packId + ":" + mdata.meshIndex;

  var svf = this.svf;
  var fragments = svf.fragments;

  var fragIndexes = fragments.mesh2frag[meshid];
  if (fragIndexes === undefined) {
    avp.logger.warn("Mesh " + meshid + " was not referenced by any fragments.");
    return;
  }
  if (!Array.isArray(fragIndexes))
  fragIndexes = [fragIndexes];

  var rm = this.model;

  // Background dbid is -1. Hide it when hideBackground is set.
  // This line has to be before calling meshToGeometry, because mdata.mesh is set to null inside of it.
  if (mdata.mesh.dbIds[-1] && this.options.hideBackground) {
    rm.changePaperVisibility(false);
  }

  //Convert the received mesh to THREE buffer geometry
  var groupMatrix = mdata.mesh.groupMatrix;
  avp.BufferGeometryUtils.meshToGeometry(mdata);

  var numInstances = fragIndexes.length;

  //Reuse previous index of this geometry, if available
  var geomId = rm.getGeometryList().addGeometry(mdata.geometry, numInstances);

  var ib = mdata.geometry.attributes['index'].array || mdata.geometry.ib;
  var polyCount = ib.length / 3;

  //For each fragment, add a mesh instance to the renderer
  for (var i = 0; i < fragIndexes.length; i++) {
    var fragId = 0 | fragIndexes[i];

    //We get the matrix from the fragments and we set it back there
    //with the activateFragment call, but this is to maintain the
    //ability to add a plain THREE.Mesh -- otherwise it could be simpler
    rm.getFragmentList().getOriginalWorldMatrix(fragId, this.tmpMatrix);

    if (this.options.placementTransform) {
      this.tmpMatrix = new THREE.Matrix4().multiplyMatrices(this.options.placementTransform, this.tmpMatrix);
    }

    if (groupMatrix) {
      this.tmpMatrix.multiply(groupMatrix);
    }

    var materialId = fragments.materials[fragId].toString();

    if (fragments.polygonCounts)
    fragments.polygonCounts[fragId] = polyCount;

    var m = this.viewer3DImpl.setupMesh(this.model, mdata.geometry, materialId, this.tmpMatrix);
    rm.activateFragment(fragId, m);
  }

  //don't need this mapping anymore.
  fragments.mesh2frag[meshid] = null;

  //Repaint and progress reporting
  fragments.numLoaded += fragIndexes.length;

  var numLoaded = fragments.numLoaded;

  this.viewer3DImpl._signalMeshAvailable();

  //repaint every once in a while -- more initially, less as the load drags on.
  if (svf.geomPolyCount > svf.nextRepaintPolys) {
    //avp.logger.log("num loaded " + numLoaded);
    svf.numRepaints++;
    svf.nextRepaintPolys += 1000 * Math.pow(1.5, svf.numRepaints);
    this.viewer3DImpl.invalidate(false, true);
  }

  if (numLoaded % 20 == 0) {
    this.viewer3DImpl.invalidate(false, true);
  }
};

PDFLoader.prototype.processReceivedMesh2D = function (mesh, mindex) {

  var mdata = { mesh: mesh, is2d: true, packId: "0", meshIndex: mindex };

  var meshId = "0:" + mindex;

  var frags = this.svf.fragments;

  //Remember the list of all dbIds referenced by this mesh.
  //In the 2D case this is 1->many (1 frag = many dbIds) mapping instead of
  // 1 dbId -> many fragments like in the SVF 3D case.
  var dbIds = Object.keys(mdata.mesh.dbIds).map(function (item) {return parseInt(item);});
  frags.fragId2dbId[mindex] = dbIds;

  //TODO: dbId2fragId is not really necessary if we have a good instance tree for the 2D drawing (e.g. Revit, AutoCAD)
  //so we can get rid of this mapping if we can convert Viewer3DImpl.highlightFragment to use the same logic for 2D as for 3D.
  for (var j = 0; j < dbIds.length; j++) {
    var dbId = dbIds[j];
    var fragIds = frags.dbId2fragId[dbId];
    if (Array.isArray(fragIds))
    fragIds.push(mindex);else
    if (typeof fragIds !== "undefined") {
      frags.dbId2fragId[dbId] = [fragIds, mindex];
    } else
    {
      frags.dbId2fragId[dbId] = mindex;
    }
  }

  frags.mesh2frag[meshId] = mindex;
  mesh.material.modelScale = this.modelScale;
  mesh.material.doNotCut = this.options.doNotCut;
  frags.materials[mindex] = this.viewer3DImpl.matman().create2DMaterial(this.model, mesh.material);

  frags.length++;

  this.processReceivedMesh(mdata);

};

PDFLoader.prototype.onModelRootLoadDone = function (svf) {

  //In the 2d case we create and build up the fragments mapping
  //on the receiving end.
  svf.fragments = {};
  svf.fragments.mesh2frag = {};
  svf.fragments.materials = [];
  svf.fragments.fragId2dbId = [];
  svf.fragments.dbId2fragId = [];
  svf.fragments.length = 0;
  svf.fragments.initialized = true;


  svf.geomPolyCount = 0;
  svf.instancePolyCount = 0;
  svf.geomMemory = 0;
  svf.fragments.numLoaded = 0;
  svf.meshCount = 0;
  svf.gpuNumMeshes = 0;
  svf.gpuMeshMemory = 0;

  svf.nextRepaintPolys = 10000;
  svf.numRepaints = 0;

  svf.urn = this.svfUrn;
  svf.acmSessionId = this.acmSessionId;

  svf.basePath = "";
  var lastSlash = this.currentLoadPath.lastIndexOf("/");
  if (lastSlash !== -1)
  svf.basePath = this.currentLoadPath.substr(0, lastSlash + 1);

  svf.loadOptions = this.options;

  var t1 = Date.now();
  this.loadTime += t1 - this.t0;
  avp.logger.log("SVF load: " + (t1 - this.t0));

  this.t0 = t1;

  //The BBox object loses knowledge of its
  //type when going across the worker thread boundary...
  svf.bbox = new THREE.Box3().copy(svf.bbox);
  svf.modelSpaceBBox = svf.bbox.clone();

  if (svf.placementTransform) {
    svf.bbox.applyMatrix4(svf.placementTransform);
  }

  //Create the API Model object and its render proxy
  var model = this.model = new av.Model(svf);
  model.loader = this;

  model.initialize();

  if (!this.options.skipPropertyDb) {
    this.svf.propDbLoader = new avp.PropDbLoader(this.sharedDbPath, this.model, this.viewer3DImpl.api);
  }

  avp.logger.log("scene bounds: " + JSON.stringify(svf.bbox));

  var metadataStats = {
    category: "metadata_load_stats",
    urn: svf.urn,
    layers: svf.layerCount };

  avp.logger.track(metadataStats);

  model.setDoNotCut(this.viewer3DImpl.matman(), !!this.options.doNotCut);

  this.viewer3DImpl.signalProgress(5);
  this.viewer3DImpl.invalidate(false, false);
};


PDFLoader.prototype.onGeomLoadDone = function () {
  this.svf.loadDone = true;

  // Don't need these anymore
  this.svf.fragments.entityIndexes = null;
  this.svf.fragments.mesh2frag = null;

  var t1 = Date.now();
  var msg = "Fragments load time: " + (t1 - this.t0);
  this.loadTime += t1 - this.t0;

  if (!this.options.skipPropertyDb) {
    this.loadPropertyDb();
  }

  avp.logger.log(msg);

  var modelStats = {
    category: "model_load_stats",
    is_f2d: true,
    has_prism: this.viewer3DImpl.matman().hasPrism,
    load_time: this.loadTime,
    geometry_size: this.model.getGeometryList().geomMemory,
    meshes_count: this.model.getGeometryList().geoms.length,
    urn: this.svfUrn };

  avp.logger.track(modelStats, true);

  this.viewer3DImpl.onLoadComplete(this.model);
};

PDFLoader.prototype.loadPropertyDb = function () {

  if (this.svf.propDbLoader) {


    // Skip loading if the shared db path is not set and dispatch 
    // a failed event instead
    if (this.svf.propDbLoader.sharedDbPath && this.svf.propDbLoader.dbPath) {
      this.svf.propDbLoader.load();
    } else {
      this.svf.propDbLoader.processLoadError({
        code: av.ErrorCodes.UNKNOWN_FAILURE, // TODO: Revisit this error code.
        msg: "PDF doesn't provide a property DB" });

    }

  }
};

PDFLoader.prototype.is3d = function () {
  return false;
};

PDFLoader.prototype.cleanup = function () {
  if (this.renderContext) {
    this.renderContext.destroy();
    this.renderContext = null;
  }

  if (this.pdf) {
    this.pdf.cleanup();
  }
};